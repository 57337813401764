// import React from "react";
// import { Link } from "react-router-dom";
// import "./Navbar.css"; 

// const Navbar = () => {
//   return (
//     <nav className="navbar">
//       <div className="navbar-logo">
//         <img src="path_to_logo" alt="Logo" />
//       </div>
//       <ul className="navbar-links">
//         <li>
//           <Link to="/">Home</Link>
//         </li>
//         <li>
//           <Link to="/salah-times">Salah Times</Link>
//         </li>
//         <li>
//           <Link to="/about-us">About Us</Link>
//         </li>
//         <li>
//           <Link to="/donate">Donate</Link>
//         </li>
//       </ul>
//     </nav>
//   );
// };

// export default Navbar;

import React, { useState } from "react";
import './Navbar.css';
import Logo from "../pics/Beacon Institute Swansea - Small.png";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <nav className="navbar">
      <a href="#home">
        <img className="small-logo" src={Logo} alt='error' />
      </a>
      <ul className={`nav-links ${isOpen ? "open" : ""}`}>
        <li><a href="#home" onClick={closeMenu}>Home</a></li>
        <li><a href="#salah-times" onClick={closeMenu}>Prayer Times</a></li>
        <li><a href="#about-us" onClick={closeMenu}>About Us</a></li>
        <li><a href="#donate" onClick={closeMenu}>Donate</a></li>
        <li><a href="#contact-us" onClick={closeMenu}>Contact Us</a></li>
      </ul>
      <div className="hamburger" onClick={toggleMenu}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
    </nav>
  );
};

export default Navbar;
