import React from "react";
import Logo from "../pics/Beacon Institute Swansea.png";
import "../components/Home.css";

const Home = () => {
  return (
    <div id="home" className="home-component">
      <img className="bigLogo" src={Logo} alt="error"></img>
      <div className="btn-container">
        <h1 className="home-title">Beacon Institute Swansea</h1>
        <a className="btn" href="#about-us">Find Out More</a>
      </div>
      
    </div>
  );
};

export default Home;



// import React from 'react';
// import '../App.css';
// import SalahTimes from './SalahTimes';

// const Home = () => {
//   return (
//     <div className="container">
      
//       {/* Home Section */}
//       <section className="section" id="home">
//         <div className="section-heading">
//           <h2>Home</h2>
//         </div>
//         <div className="section-content">
//           <p>
//             Welcome to our Salah Times app! This app provides accurate prayer times and allows you to learn more about us and support our cause.
//           </p>
//         </div>
//       </section>

//       {/* Salah Times Section */}
//       <section className="section" id="salah-times">
//         <div className="section-heading">
//           <h2>Salah Times</h2>
//         </div>
//         <div className="section-content">
//           <p>
//             The latest prayer times are displayed here. You can check the timings for Fajr, Zuhr, Asr, Maghrib, and Isha, along with Jamah and Sunrise times.
//           </p>
//           {SalahTimes}
//         </div>
//       </section>

//       {/* About Us Section */}
//       <section className="section" id="about-us">
//         <div className="section-heading">
//           <h2>About Us</h2>
//         </div>
//         <div className="section-content">
//           <p>
//           Beacon Institute Swansea is an organisation dedicated to making a meaningful difference in our community. 
//         Established with a commitment to advancing the Islamic faith, we strive to create a welcoming environment where individuals from all walks of 
//         life can benefit from spiritual growth, education, and feel a sense of belonging. Our work is guided by a sense of social responsibility and a 
//         deep desire to uplift and empower the entire community, including the students who are an integral part of it.<br/><br/>Our mission is to address 
//         the diverse needs of our community, with a focus on both spiritual and social well-being. We provide essential facilities for education, 
//         recreation, and leisure activities, ensuring that everyone has the opportunity to thrive.<br/><br/>Originally founded to offer a welcoming prayer 
//         space and a place for iftar for students in Swansea, Beacon Institute has since grown to support the broader community. While we continue to 
//         honor our roots by supporting students, we are equally committed to serving the wider community. We ensure that all who come through our doors, 
//         whether residents or students, find a place where they can gather, pray, and feel at home.<br/><br/>As we continue to grow and evolve, we remain 
//         open to exploring other charitable initiatives that align with our mission. Guided by our Trustees, Beacon Institute Swansea is committed to 
//         being a beacon of hope, support, and unity for the entire community.
//           </p>
//         </div>
//       </section>

//       {/* Donate Section */}
//       <section className="section" id="donate">
//         <div className="section-heading">
//           <h2>Donate</h2>
//         </div>
//         <div className="section-content">
//           <p>
//             Support our cause by donating. Your contribution helps us continue to provide valuable services to the community.
//           </p>
//         </div>
//       </section>
      
//     </div>
//   );
// };

// export default Home;