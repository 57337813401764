// import React from "react";
// import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import Navbar from "./components/Navbar";
// import Home from "./components/Home";
// import SalahTimes from "./components/SalahTimes";
// import AboutUs from "./components/AboutUs";
// import Donate from "./components/Donate";
// import "./App.css";

// const App = () => {
//   return (
//     <Router>
//       <Navbar />
//       <Routes>
//         <Route path="/" element={<Home />} />
//         <Route path="/salah-times" element={<SalahTimes />} />
//         <Route path="/about-us" element={<AboutUs />} />
//         <Route path="/donate" element={<Donate />} />
//       </Routes>
//     </Router>
//   );
// };

// export default App;

import React from "react";
import "./App.css";
import Home from "./components/Home";
import SalahTimes from "./components/SalahTimes";
import AboutUs from "./components/AboutUs";
import Donate from "./components/Donate";
import Navbar from "./components/Navbar";
import ContactUs from "./components/ContactUs";

const App = () => (
  <div>
    <Navbar />
    <Home />
    <SalahTimes />
    <AboutUs />
    <Donate />
    <ContactUs />
  </div>
);

export default App;
